import { Box } from "@mui/material";
import { LargeSpinner } from "components/atoms/LargeSpinner";
import { CreateLogin } from "components/modules/login/CreateLogin";
import { OnboardingFlow } from "components/modules/onboarding/OnboardingFlow";
import { DisinfectionDashboardPage } from "components/pages/DisinfectionDashboardPage";
import { DisinfectionsByRoomPage } from "components/pages/DisinfectionsByRoomPage";
import { MigrationPage } from "components/pages/MigrationPage";
import { OrganizationPage } from "components/pages/OrganizationPage";
import { OrganizationRobotListPage } from "components/pages/OrganizationRobotListPage";
import { OrganizationUserListPage } from "components/pages/OrganizationUserListPage";
import { ProfilePage } from "components/pages/ProfilePage";
import { RobotPage } from "components/pages/RobotPage";
import { SupportOrganizationListPage } from "components/pages/SupportOrganizationListPage";
import { SupportRobotListPage } from "components/pages/SupportRobotListPage";
import { SupportUserListPage } from "components/pages/SupportUserListPage";
import { UnassignedRobotListPage } from "components/pages/UnassignedRobotListPage";
import { UserPage } from "components/pages/UserPage";
import _ from "lodash";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useQueryWithSnack } from "utils/useQueryWithSnack";
import { Login } from "../components/modules/login/Login";
import { ResetPassword } from "../components/modules/login/ResetPassword";
import { ContentPage } from "../components/pages/ContentPage";
import { NoMatch } from "../components/pages/NoMatch";
import { Dashboard } from "../components/templates/Dashboard";
import { LoginWrapper } from "../components/templates/LoginWrapper";
import { useConfig } from "../config/configHook";
import { useFirebase } from "../firebaseWrapper";
import { graphql } from "../gql";
import { ROUTES } from "./Routes";

const GET_MODULES = graphql(`
  query RoutingGetModules {
    me {
      supportRole {
        title
        privileges
      }
      affiliations {
        id
        organization {
          id
        }
        roles {
          privileges
        }
      }
    }
  }
`);

export const Routing = () => {
  const config = useConfig();
  const firebase = useFirebase(config);
  const [user, loading] = useAuthState(firebase.auth);

  // const canMigrate = user
  //   ? usePrivilege(Privilege.Migrate).hasPrivilege
  //   : false;

  const { data: modulesData, loading: loadingData } = useQueryWithSnack(
    GET_MODULES,
    {
      skip: !user || loading,
    },
  );

  const firstAffiliation =
    modulesData?.me?.affiliations && modulesData.me.affiliations.length > 0
      ? _.first(modulesData?.me.affiliations)
      : null;

  const defaultRoute = modulesData?.me.supportRole
    ? ROUTES.support.disinfections
    : firstAffiliation
      ? `/cloud/fm/${firstAffiliation.organization.id}/disinfections`
      : "/cloud/profile";

  if (loadingData)
    return (
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.background.page,
          minHeight: "100vh",
          display: "flex",
          position: "absolute",
          minWidth: "100%",
        })}
      >
        <LargeSpinner />
      </Box>
    );

  return (
    <Routes>
      <Route path="/">
        <Route element={<LoginWrapper />}>
          <Route index element={<Navigate to={ROUTES.login.default} />} />
          <Route path={ROUTES.login.default} element={<Login />} />
          <Route path="/invite/:encodedUserEmail" element={<CreateLogin />} />
          <Route
            path={ROUTES.login.resetPassword}
            element={<ResetPassword />}
          />
        </Route>
        <Route path={ROUTES.agreements} element={<ContentPage />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route element={<LoginWrapper />}>
        <Route index path="onboarding" element={<OnboardingFlow />} />
      </Route>
      <Route path="cloud" element={<Dashboard defaultRoute={defaultRoute} />}>
        <Route index element={<Navigate to={defaultRoute} />} />
        <Route path={ROUTES.profile} element={<ProfilePage />} />
        {modulesData?.me.supportRole ? (
          <Route path="support" element={<Outlet />}>
            <Route
              index
              element={<Navigate to={ROUTES.support.organizations} />}
            />
            <Route
              path={ROUTES.support.organizations}
              element={<SupportOrganizationListPage />}
            />
            <Route
              path={ROUTES.support.organization}
              element={<OrganizationPage />}
            />
            <Route
              path={ROUTES.support.orgRoomList}
              element={<DisinfectionsByRoomPage />}
            />
            <Route
              path={ROUTES.support.robots}
              element={<SupportRobotListPage />}
            />
            <Route
              path={ROUTES.support.unassignedRobots}
              element={<UnassignedRobotListPage />}
            />
            <Route path={ROUTES.support.robot} element={<RobotPage />} />
            <Route
              path={ROUTES.support.unassignedRobots}
              element={<RobotPage />}
            />
            <Route
              path={ROUTES.support.unassignedRobot}
              element={<RobotPage />}
            />
            <Route
              path={ROUTES.support.users}
              element={<SupportUserListPage />}
            />
            <Route path={ROUTES.support.user} element={<UserPage />} />
            <Route
              path={ROUTES.support.disinfections}
              element={<DisinfectionDashboardPage />}
            />
            <Route
              path={ROUTES.support.migration}
              element={<MigrationPage />}
            />
            <Route path="*" element={<NoMatch />} />
          </Route>
        ) : undefined}
        {modulesData?.me.affiliations?.length !== 0 ? (
          <Route path="fm/:orgId" element={<Outlet />}>
            <Route
              index
              element={<Navigate to={ROUTES.organization.disinfections} />}
            />
            <Route
              path={ROUTES.organization.robots}
              element={<OrganizationRobotListPage />}
            />
            <Route path={ROUTES.organization.robot} element={<RobotPage />} />
            <Route
              path={ROUTES.organization.users}
              element={<OrganizationUserListPage />}
            />
            <Route path={ROUTES.organization.user} element={<UserPage />} />
            <Route
              path={ROUTES.organization.settings}
              element={<OrganizationPage />}
            />
            <Route
              path={ROUTES.organization.disinfections}
              element={<DisinfectionDashboardPage />}
            />
            <Route
              path={ROUTES.organization.rooms}
              element={<DisinfectionsByRoomPage />}
            />
            <Route path="*" element={<NoMatch />} />
          </Route>
        ) : undefined}
      </Route>
      <Route
        path="*"
        element={
          <Box style={{ minHeight: "100vh" }}>
            <NoMatch />
          </Box>
        }
      />
    </Routes>
  );
};
